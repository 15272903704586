import {Swiper} from "swiper/types"
import {sameHeight} from "./../../../js/same-height/same-height"
import {compareApi} from "./../../../js/compare-api/compare-api"

interface SwiperInstance extends HTMLElement {
  swiper?: Swiper
}

document.querySelectorAll(".g-grader").forEach((group) => {
  const swiperElement = group.querySelector<SwiperInstance>(".g-grader-slider")

  sameHeight(group, ".i-grader-slide__param.--title, .g-grader__label:first-child")
  sameHeight(group, ".i-grader-slide__param.--price, .g-grader__label:nth-child(2)")
  sameHeight(group, ".i-grader-slide__param.--aviability, .g-grader__label:nth-last-child(2)")
  sameHeight(group, ".i-grader-slide__param.--button, .g-grader__label:last-child")

  const cols = group.querySelector(".i-grader-slide")?.querySelectorAll(".i-grader-slide__param.--basic").length ?? 0

  for (let index = 0; index < cols; index++) {
    sameHeight(group, `.i-grader-slide__param.--basic.--col-${index}, .g-grader__label:nth-child(${index + 3})`)
  }

  group.addEventListener("slidesLengthChange", async () => {
    const slidesCount = group.querySelectorAll<HTMLElement>(".swiper-slide").length - 1

    const itemsOrder = [...group.querySelectorAll<HTMLElement>(".i-grader-slide")].map((item) => {
      return item.dataset.productId ?? ""
    })

    console.log(itemsOrder)

    group.classList.add("--loading")

    await compareApi.sort(itemsOrder)

    const targetTab = group.getAttribute("data-content-id") ?? ""
    const tab = document.querySelector(`[data-tab-target="${targetTab}"]`)
    const tabCountValue = tab?.querySelector(".i-grader-tab__count-value")

    if (tabCountValue) {
      tabCountValue.innerHTML = String(slidesCount)
    }

    if (slidesCount === 0) {
      const tabClearButton = tab?.querySelector(".i-grader-tab__count")
      tabClearButton?.dispatchEvent(new Event("click"))
    }

    swiperElement?.swiper?.update()

    group.classList.remove("--loading")
  })
})
