import {mobileQuery} from "./main-nav"

export {}

const headerTopContent = document.querySelector(".m-header-top__grid")
const mainNavBottom = document.querySelector(".main-nav__bottom")
const headerTopContainer = document.querySelector(".m-header-top__container")

if (headerTopContent && mainNavBottom && headerTopContainer) {
  const handleMobileChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList) => {
    if (mediaQueryList.matches) {
      // Its Mobile
      mainNavBottom.prepend(headerTopContent)
    } else {
      // Its Desktop
      headerTopContainer.prepend(headerTopContent)
    }
  }

  mobileQuery.addEventListener("change", handleMobileChange)
  handleMobileChange(mobileQuery)
}
