export const sameHeight = (group: Element, elements: string | NodeListOf<HTMLElement>, byRow = false) => {
  const targets = elements instanceof NodeList ? Array.from(elements) : Array.from(group.querySelectorAll<HTMLElement>(elements))

  const getHighestElement = (elements: HTMLElement[] = targets): number => {
    const heights = elements.map((box) => box.clientHeight)
    return Math.max(...heights)
  }

  const resetHeight = (elements: HTMLElement[] = targets) => {
    elements.forEach((element) => element.style.removeProperty("height"))
  }

  const updateHeight = (elements: HTMLElement[] = targets) => {
    const theHighest = `${getHighestElement(elements)}px`
    elements.forEach((element) => element.style.setProperty("height", theHighest))
  }

  const updateByRow = () => {
    const rows = Array.from(new Set(targets.map((target) => target.offsetTop)))
    const elementsCollection: HTMLElement[][] = []

    let targetsToFilter = targets

    rows.forEach((row) => {
      const elementsInSameRow = targets.filter((target) => target.offsetTop === row)
      elementsCollection.push(elementsInSameRow)
      targetsToFilter = targetsToFilter.filter((item) => !elementsInSameRow.includes(item))
    })

    elementsCollection.forEach((elements) => updateHeight(elements))
  }

  const resizeObserver = new ResizeObserver(() => {
    resetHeight()
    byRow ? updateByRow() : updateHeight()
  })

  resizeObserver.observe(group)
}

const init = () => {
  const groups = document.querySelectorAll(`[data-same-height]`)

  groups.forEach((group) => {
    const byRow = group.hasAttribute("data-same-height-by-row")

    const targets = Array.from(group.querySelectorAll<HTMLElement>(`[data-same-height-target]`))
    const collections = Array.from(new Set(targets.map((target) => target.getAttribute("data-same-height-target"))))

    collections.forEach((collection) => {
      const collectionElements = group.querySelectorAll<HTMLElement>(`[data-same-height-target="${collection}"]`)
      sameHeight(group, collectionElements, byRow)
    })
  })
}

// call after rerender -> document.dispatchEvent(new Event("sameHeight")) -> reinitialize
document.addEventListener("sameHeight", () => init())
init()
