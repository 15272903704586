import {ProgressItem} from "../../components/items/progress-item/progress-item"
import {TotalPriceItem} from "../../components/items/total-price-item/total-price-item"
import {cartApi, cartData} from "./../cart-api/cart-api"

export const initCartAll = () => {
  const dropdowns = document.querySelectorAll(".i-header-dropdown.--cart")
  const totalPrice = new TotalPriceItem(document.querySelector(".i-total-price"))
  const progress = new ProgressItem(document.querySelector(".i-progress"))

  dropdowns.forEach((dropdown) => {
    const itemsSelector = ".i-dropdown-product.--delete-single-cart"
    const clearButton = dropdown.querySelector(".--js-clear-cart")
    const section = dropdown.querySelector(".s-dropdown-product")
    const wrapper = dropdown.querySelector(".s-dropdown-product__buttons")

    clearButton?.addEventListener("click", async (event: Event) => {
      event.preventDefault()

      wrapper?.classList.add("--loading")

      const response = await cartApi.deleteAll()

      if (!response.ok) {
        wrapper?.classList.remove("--loading")
        return
      }

      const data = (await response.json()) as cartData

      console.log(data)

      if (!data.success) {
        wrapper?.classList.remove("--loading")
        return
      }

      if (data.progress.remaining && data.progress.percentage) {
        progress.setProgress(data.progress.remaining, Number(data.progress.percentage))
      }

      if (data.totalPrice.base) totalPrice.setBaseValue(data.totalPrice.base)
      if (data.totalPrice.vatExcludedValue) totalPrice.setVatExcludedValue(data.totalPrice.vatExcludedValue)

      dropdown.querySelectorAll(itemsSelector).forEach((item) => {
        item.remove()
      })

      document.querySelectorAll(".i-cart").forEach((item) => {
        item.remove()
      })

      if (data.gtm) {
        window.dataLayer.push({ecommerce: null})
        window.dataLayer.push(data.gtm)
      }
      console.log(window.dataLayer)

      addToCartButtonsReset()

      section?.classList.add("--empty")
      dropdown.classList.add("--open")
      dropdown.classList.remove("--fill")
      wrapper?.classList.remove("--loading")
    })
  })
}

initCartAll()

const addToCartButtonsReset = () => {
  const buttons = document.querySelectorAll<HTMLElement>(".--js-cart-add.--added")

  buttons.forEach((button) => {
    const buttonTitle = button.querySelector(".btn__title") ?? null
    const textDefault = button.dataset.textDefault ?? null

    if (buttonTitle && textDefault) {
      buttonTitle.innerHTML = textDefault
      button.classList.remove("--added")
    }
  })
}
