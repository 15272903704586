const menuItems = document.querySelectorAll(".i-main-navigation.--megamenu")

menuItems.forEach((menuItem) => {
  const dropdownButton = menuItem.querySelector<HTMLElement>(".i-main-navigation__dropdown-button")
  const megamenu = menuItem.querySelector<HTMLElement>(".i-megamenu")

  if (!megamenu) return

  dropdownButton?.addEventListener("click", (event) => {
    event.preventDefault()
    dropdownButton.classList.toggle("--active")
    megamenu.classList.toggle("--active")
  })
})
