import "./../css/style.css"

import "./cart-dropdown"
import "./compare-dropdown"
import "./cart-flow"
import "./../components/main-nav"
import "./../components/items/main-navigation-item/main-navigation-item"
import "./same-height"
import "../components/base-form/base-form"

import {lazyLoading} from "./lazyload/lazyload-init"

window.lazyloading = lazyLoading // add lazyloading to global window object

window.onpageshow = function (event: PageTransitionEvent) {
  if (event.persisted) {
    window.location.reload()
  }
}

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("./fancybox/fancybox")
  }
}

async function loadMain() {
  await import("./overlayscrollbars")
  await import("./main")
  loadFancybox()
}
loadMain()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper-init")
  }
}
loadSwiper()

async function loadTippy() {
  if (document.querySelectorAll(".i-deliverity, [data-tippy-content]").length) {
    await import("./tippy")
  }
}
loadTippy()

async function loadSlimSelect() {
  if (document.querySelectorAll(".slim-select").length) {
    await import("./slim-select/slim-select")
  }
}
loadSlimSelect()

async function loadMultiCheckboxSelect() {
  if (document.querySelectorAll(".se-multi-checkbox").length) {
    await import("./../components/selects/multi-checkbox-select")
  }
}
loadMultiCheckboxSelect()

async function loadGraderItem() {
  if (document.querySelectorAll(".i-grader-slide").length) {
    await import("./../components/items/grader-slide-item/grader-slide-item")
  }
}
loadGraderItem()

async function loadGiftItem() {
  if (document.querySelectorAll(".i-gift").length) {
    await import("./../components/items/gift-item/gift-item")
  }
}
loadGiftItem()

async function loadRotateSlider() {
  if (document.querySelectorAll(".g-rotate-slider").length) {
    await import("../components/groups/rotate-slider-group/rotate-slider-group")
  }
}
loadRotateSlider()
